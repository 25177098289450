/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          function scrollTest() {
              if ($(document).scrollTop() > 61) {
                  $("body").addClass("scrolled");
              } else {
                  $("body").removeClass("scrolled");
              }
          }

          $(document).ready(function() {
              scrollTest();
          });
          $(window).scroll(function () {
              scrollTest();
          });

          jQuery(".modern-ticker").modernTicker({
              effect: "scroll",
              scrollType: "continuous",
              scrollStart: "inside",
              scrollInterval: 20,
              transitionTime: 500,
              linksEnabled: true,
              pauseOnHover: true,
              autoplay: true
          });


          $('.inner-slideshow .item').css('display','block');
          $('.inner-slideshow-thumbs').slideDown();

          $('.inner-slideshow').slick({
              autoplay: true,
              autoplaySpeed: 4000,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 500,
              arrows: true,
              dots: true,
              fade: false,
              asNavFor: '.inner-slideshow-thumbs'
          });

          $('.inner-slideshow-thumbs').slick({
              slidesToShow: 4,
              slidesToScroll: 1,
              speed: 500,
              asNavFor: '.inner-slideshow',
              dots: false,
              centerMode: true,
              focusOnSelect: true,
              centerPadding: '60px',
              responsive: [
                  {
                      breakpoint: 1199,
                      settings: {
                          centerMode: true,
                          centerPadding: '40px',
                          slidesToShow: 4
                      }
                  },
                  {
                      breakpoint: 991,
                      settings: {
                          centerMode: true,
                          centerPadding: '40px',
                          slidesToShow: 4
                      }
                  },
                  {
                      breakpoint: 767,
                      settings: {
                          centerMode: true,
                          centerPadding: '40px',
                          slidesToShow: 3
                      }
                  },
                  {
                      breakpoint: 479,
                      settings: {
                          centerMode: true,
                          centerPadding: '40px',
                          slidesToShow: 3
                      }
                  }
              ]
          });

          var slides = $(".inner-slideshow .slick-track > .slick-slide").length;
          $('.inner-slideshow').on('afterChange', function(event, slick, currentSlide, nextSlide){
              var inFocus = $('.inner-slideshow .slick-current').attr('data-slick-index');
              $('.inner-slideshow-thumbs .slick-current').removeClass('slick-current');
              $('.inner-slideshow-thumbs .slick-slide[data-slick-index="' + inFocus + '"]').trigger('click');
          });


          $(function () {
              $('[data-toggle="popover"]').popover({trigger:'hover'});
          });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }


    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


          $('.latest-news-slider').slick({
              infinite: true,
              speed: 300,
              slidesToShow: 3,
              autoplay: true,
              slidesToScroll: 1,
              responsive: [
                  {
                      breakpoint: 1199,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                          infinite: true
                      }
                  },
                  {
                      breakpoint: 991,
                      settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1,
                          infinite: true
                      }
                  },
                  {
                      breakpoint: 767,
                      settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          infinite: true
                      }
                  }
              ]
          });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.











(function($,sr){

    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
        var timeout;

        return function debounced () {
            var obj = this, args = arguments;
            function delayed () {
                if (!execAsap) {
                    func.apply(obj, args);
                }
                timeout = null;
            }

            if (timeout) {
                clearTimeout(timeout);
            }
            else if (execAsap) {
                func.apply(obj, args);
            }

            timeout = setTimeout(delayed, threshold || 100);
        };
    };
    // smartresize
    jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');
